* {
  box-sizing: border-box;
  user-select: none;    
}

html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow:hidden;
    line-height: 1;
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
    -ms-overflow-style: none;
  }
}

::-webkit-scrollbar { width: 0 !important }

a img {
  /* Stops ie from displaying a blue box around linked images */
  outline: 0;
  border: 0;
}

#map_canvas {
  width: 100%;
  height: 100%;
}

#custom-popup {
  //position:absolute;
  display: inline-block;
  top: 0;
  right: 0;
  z-index: 10;
  ul {
    margin:0;
    padding:0;
    list-style-type:none;
    li {
      position:relative;
      display:block;
      vertical-align:top;
    }
  }
}

.map-controls {
  position:absolute;
  bottom:30px;
  width: 100%;
  //left:240px;
  
  z-index:10;
  text-align:center;
  a {
    display: inline-block;
    margin:0px -2px;
    width:64px;
    height:64px;
    padding:0px;
    text-decoration:none;
    line-height:1;
    color:$white;
  }  
  .map-styles {
    display: inline-block;
    margin:0px 30px;
  }

  #roadmap-btn, #hybridmap-btn, #satellitemap-btn, #zoomin-btn, #zoomout-btn, #center-btn {
    width:64px;
    height:64px;
    overflow:hidden;
    text-indent:-9999px;
  }

  #roadmap-btn {
    background: #fff url("../images/icons/icon-road.svg") no-repeat center center;
    background-size:60% auto;
    &.active {
      background-color:#ccc;
    }
  }

  #hybridmap-btn {
    background: #fff url("../images/icons/icon-hybrid.svg") no-repeat center center;
    background-size:60% auto;
    &.active {
      background-color:#ccc;
    }
  }

  #satellitemap-btn {
    background: #fff url("../images/icons/icon-satellite.svg") no-repeat center center;
    background-size:60% auto;
    &.active {
      background-color:#ccc;
    }
  }

  #zoomin-btn {
    background: #fff url("../images/icons/icon-plus.svg") no-repeat center center;
    background-size:60% auto;
  }

  #zoomout-btn {
    background: #fff url("../images/icons/icon-minus.svg") no-repeat center center;
    background-size:60% auto;
  }

  #center-btn {
    background: #fff url("../images/icons/icon-center_map.svg") no-repeat center center;
    background-size:60% auto;
  }
}

///// Info Windows
.parent-info-window {
.gm-style-iw {
    position:relative;
    width: 280px !important;
    top: 15px !important;
    left: 0px !important;
    background-color: #fff;
    padding: 18px;
    > div {
      max-width: initial !important;
      max-height: initial !important;
      width:100%;
    }
    .info_window {
      h3 {
        margin:0;
        //margin-top:15px;
        font-size: 20px;
        color:#545759;
      }
      p {
          margin:0;
          padding-top:18px;
          padding-right:40px;
          font-size: 12px;
          font-weight: 400;
          margin-right: 1px;
          overflow-y: auto;
          overflow-x: hidden;
          color:#545759;
          line-height:1.5;
          strong {
              color:#545759;
          }
          a {
              color:#545759;
              text-decoration:none;
          }
      }
      img {
        width:100%;
      }
    }
}
}
.parent-info-window {
    width:280px !important;
}
.infoWindowCloseBtn {
  //width:35px !important;
  //height:35px !important;
  background: url('http://cdn.touchtour.engrain.io/map/v2/info-window-close.png') no-repeat center center !important;
  background-size: 100% 100% !important;
}

.custom-zoom-levels {
  a {
    display:block;
    min-width:92px;
    width:auto;
    height:auto;
    background:#fff;
    padding:10px;
    color:#000;
    text-align:center;
    font-size:8px;
    text-transform:uppercase;
  }
}
