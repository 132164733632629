#popup {
  display: none;
  position: absolute;
  width: 60%;
  height: 80%;
  top: 50%;
  left: 50%;
  background: #fff;
  padding: 15px;
  z-index: 20;
  transform: translate(-50%, -50%);
  .popup-content {
    overflow-y: scroll;
    overflow-x:hidden;
  }

  h1 {
    margin:0;
    margin-top:15px;
    font-size:28px;
    font-weight:normal;
    color:#6b6c7f;
  }

  p {
    margin:0;
    margin-top:10px;
    padding-right:60px;
    font-size:20px;
    color:#6b6c7f;
    line-height: 1.5;
  }

  img {
    width:100%;
  }

  .close {
    position:absolute;
    bottom:0;
    right:0;
    width:52px;
    height:52px;
    background:#127ea2 url("../images/icons/icon-close.svg") no-repeat center center;
    background-size:50% 50%;
  }
}

