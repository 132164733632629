.custom-btn {
  cursor: pointer;
  height: 66px;
  width: 66px;
  background: rgba($black, 0.8);
  margin:0;
  text-align:center;
  background-position: center center;
  background-repeat: no-repeat;
  img {
    height:100%;
    width:auto;
  }
  &.active {
    
  }
}

#cd-bike {
  background-image: url("../images/icons/icon-bike.png");
  background-size: 100% 100%;
}

#cd-roadMap, #cd-hybrid, #cd-satellite, #cd-zoom-in, #cd-zoom-out, #cd-center {
  width:64px;
  height:45px;
  overflow:hidden;
  text-indent:-9999px;
  margin-right:520px;
  visibility:hidden;
}