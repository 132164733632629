.sidebar {
  position:absolute;
  top:0;
  left:0;
  width:300px;
  height:100%;
  //background:#000000;
  z-index:25;
}
.sidebar-content {
  position:absolute;
  top: 50%;
  transform: translateY(-50%);
  right:0;
  width:100%;
  padding:0 25px 25px;
}
.listTitle {
  font-weight:bold;

}

#location-categories {
  margin:0;
  padding:0;
  list-style-type: none;
  width:100%;
  li {
    position:relative;
    top:0;
    right:0;
    width:100%;
    transition: all 0.5s;
    a {
      display: block;
      padding:12px 25px;
      margin:12px;
      //background:transparent;
      background:#fff;
      color:#545759;
      font-size:18px;
      text-decoration: none;
      text-align: center;
      //text-transform:uppercase;
    }
    &.selected {
      a {
        background:#545759;
        color:#fff;
      }
    }
  }

  .list-container {
    position:relative;
    transition:1.5s all;
    .neighborhood-list-wrap{
      background:transparent;
      padding:0px 0px;
      //height:275px;
      overflow-y:scroll;
      overflow-x:hidden;
      margin-top: -12px;
    }
    ul {
      margin:0;
      padding:0;
      list-style-type: none;
      padding:0 0;
      counter-reset: location;
      li {
        position:relative;
        margin-bottom:0;
        &:before {
          position:absolute;
          top:7px;
          left:18px;
          width:18px;
          height:18px;
          counter-increment: location;
          content: counter(location);
          font-size:13px;
          font-weight:bold;
          border-radius:50%;
          text-align:center;
          line-height:18px;
          color:#555759;
          background:transparent;
        }
        &.active {
          &:before {
            //background:#555759;
            //color:#fff;
          }
        }
        a {
          padding:10px 10px 20px 30px;
          //background:#fff;
          //background-color
          background-color:rgba(255,255,255,0.6);
          margin-bottom:-12px;
          display: block;
          text-decoration: none;
          color:#545759;
          font-size:13px;
          height:auto;
          text-align:left;
        }
      }
    }
  }
}

.active-category {
  right:0 !important;
}

