#shelf{
  //position:absolute;
  top:0;
  right:0;
  height:64px;
  width:64px;
  background:#fff;
  z-index:100;
  //transform: rotate(-90deg);
}
#draggable {
  position:absolute;
  top:8px;
  right:8px;
  z-index:100;
}

#reset-draggable {
  position:absolute;
  top:8px;
  right:8px;
  z-index:90;
}

#custom-popup ul li {
  #direction-mode {
    position:absolute;
    top:0;
    right:0;
    background:#fff;
    z-index:100;
    transition:all 0.5s;
    transform: rotate(-90deg);
    transform-origin: top right;
    width: 64px;
    height:0;
    overflow:hidden;
    a {
      display: block;
      width:64px;
      height:64px;
      background:#fff;
      overflow: hidden;
      text-indent:-9999px;
      &.driving {
        background:#fff url('../images/icons/icon-driving_directions2.svg') no-repeat center center;
        background-size:50% 50%;
        transform: rotate(90deg);
      }
      &.walking {
        background:#fff url('../images/icons/icon-walking_directions2.svg') no-repeat center center;
        background-size:50% 50%;
        transform: rotate(90deg);
      }
      &.bicycling {
        background:#fff url('../images/icons/icon-bike_directions2.svg') no-repeat center center;
        background-size:50% 50%;
        transform: rotate(90deg);
      }
      &.transit {
        background:#fff url('../images/icons/icon-transit_directions2.svg') no-repeat center center;
        background-size:50% 50%;
        transform: rotate(90deg);
      }
      &.active {
        background-color:#ccc;
        border:0;
        transform: rotate(90deg);
      }
    }
  }
}

.overlay {
  color:#fff;
  font-size:18px;
  font-weight:bold;
}