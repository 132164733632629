$black : #000;
$white : #fff;


$locations-container--width       : '200px';


// Category Buttons
$category-btn--padding            : '10px';
$category-btn--fontsize           : '15px';

$category-btn--background         : #000;
$category-btn--text               : #000;
$category-btn-active--background  : #000;
$category-btn-active--text        : #000;


// Locations Group Container 
$locations-container--background  : #000;
$locations-container--height      : '320px';
$locations-container--spacing     : '15px';
$locations-container--padding     : '10px 0';

// Location Buttons
$location-btn--padding            : '10px 10px 10px 65px';
$location-btn--fontsize           : '18px';

$location-btn--background         : #000;
$location-btn--text               : #000;
$location-btn--number-bg          : #000;
$location-btn--number-text        : #000;

$location-btn-active--background  : #000;
$location-btn-active--text        : #000;
$location-btn-active--number-bg   : #000;
$location-btn-active--number-text : #000;


// Map Controls Container
$map-controls-container--padding    : '10px 0';
$map-controls-container--background : #000;

// Map Controls
$map-controls--size                 : '42px';
$map-controls--spacing              : '0px 0px'; // margin
$map-controls--background           : #000;
$map-controls--active-background    : #000;


// Overlay Buttons
$overlay-btn--minwidth           : '90px';
$overlay-spacing                 : '5px 0px'; // margin
$overlay-btn--padding            : '10px 10px 10px 65px';
$overlay-btn--fontsize           : '18px';

$overlay-btn--background         : #000;
$overlay-btn--text               : #000;
