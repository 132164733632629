.slick-arrow {
  position:absolute;
  top:50%;
  transform: translateY(-50%);
  width:50px;
  height:50px;
  outline:0;
  border:0;
  overflow: hidden;
  text-indent:-9999px;
  background-size:100% 100%;
  z-index: 10;
}

.slick-prev {
  left:0;
  background: url("../images/icons/icon-previous.svg") no-repeat center center;
}

.slick-next {
  right:0;
  background: url("../images/icons/icon-next.svg") no-repeat center center;
}